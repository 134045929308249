export type MainStreamListInfoMode = "newSearch" | "byTemplate";

export interface SupplementalPayloadI {
  count: number;
  sumCost: number;
  unseenCountLots?: number | null;
  unseenSumCost?: number | null;
  selective?: boolean;
}

export interface MainStreamListInfoOptions {
  info: SupplementalPayloadI;
  loading: boolean;
  request: {
    cancel: any;
  } | null;
}

export interface MainStreamListInfoMeta {
  part: number;
  mode: MainStreamListInfoMode;
  isOver?: boolean;
  loading?: boolean;
  error?: boolean;
  request?: {
    cancel: any;
  } | null;
}

export interface MainStreamListInfo {
  meta: MainStreamListInfoMeta;
  options: MainStreamListInfoOptions;
  show: boolean;
}

export interface RequestCommonInfo {
  loading: boolean,
  request: any,
  error: boolean,
  isOver?: boolean,
  count?: number,
}

export interface FilteredEntity {
  data: Array<any>,
  info: RequestCommonInfo,
}

export const getDefaultFilteredEntity = (): FilteredEntity => {
  return {
    data: [],
    info: getDefaultRequestCommonInfo(),
  }
}

export function getDefaultRequestCommonInfo(): RequestCommonInfo {
  return {
    loading: false,
    request: null,
    error: false,
    isOver: false,
    count: 0,
  }
}

export const getEntityListInfo = (mode?: MainStreamListInfoMode): MainStreamListInfo => {
  return {
    meta: {
      part: 0,
      mode: mode ? mode : 'newSearch',
      loading: false,
      request: null,
      isOver: false,
      error: false,
    },
    options: {
      info: {
        count: 0,
        sumCost: 0,
        unseenCountLots: null,
        unseenSumCost: null,
        selective: false,
      },
      request: null,
      loading: false,
    },
    show: false,
  };
};

export function getEntityListInfoSelective(): MainStreamListInfo {
  return {
    meta: {
      part: 0,
      mode: 'newSearch',
      loading: false,
      request: null,
      isOver: false,
      error: false,
    },
    options: {
      info: {
        count: 0,
        sumCost: 0,
        unseenCountLots: null,
        unseenSumCost: null,
        selective: true,
      },
      request: null,
      loading: false,
    },
    show: false,
  }
}
